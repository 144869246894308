<template>
  <div>
    <EditDialog width="80%" :isReturn="true" @closeFn="$emit('offsetCancel')" :isShow.sync="isShow">
      <div slot="title" class="title">冲红单据详情</div>
      <div slot="content" class="content" v-loading="loading">
        <el-form ref="form" :model="form">
          <el-descriptions border class="margin-top w_100" :column="8" direction="vertical">
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              label="单据编号"
            >
              <el-form-item prop="expenseNumber" style="width: 100%">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              label="申请人"
            >
              <el-form-item prop="createName" style="width: 100%">
                {{ form.createName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label"> 申请日期 </template>
              <el-form-item prop="taskDate" style="width: 100%">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="1"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开票日期
              </template>
              <el-form-item ref="invoiceDate" prop="invoiceDate" style="width: 100%">
                {{ form.invoiceDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="1"
            >
              <template slot="label">
                <span style="color: red">*</span>
                销售方
              </template>
              <el-form-item ref="companyType" prop="companyType" style="width: 100%">
                {{ form.companyType | dict(dictData.companyType) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                购买方
              </template>
              <el-form-item ref="customersUnit" prop="customersUnit" style="width: 100%">
                {{ form.customersUnit }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                申请类型
              </template>
              <el-form-item
                ref="invoiceApplicationType"
                prop="invoiceApplicationType"
                style="width: 100%"
                >{{ form.invoiceApplicationType | dict(dictData.invoiceApplicationType) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                纳税人识别号
              </template>
              <el-form-item ref="taxpayerNumber" prop="taxpayerNumber" style="width: 100%">
                {{ form.taxpayerNumber }}
              </el-form-item>
            </el-descriptions-item>

            <template v-if="form.invoiceApplicationType != 'PROJECT_CONTRACT_PENDING'">
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                :span="
                  projectSourceRules
                    ? 6
                    : deputyGeneralRules && !agreementRules
                    ? 3
                    : deputyGeneralRules
                    ? 4
                    : 5
                "
              >
                <template slot="label">
                  <span style="color: red">{{ projectRules ? '*' : '' }}</span>
                  项目名称
                </template>
                <el-form-item ref="projectName" prop="projectName">
                  {{ form.projectName }}
                </el-form-item>
              </el-descriptions-item>

              <!-- 不立项不补签合同、有合同未立项（不需要补立项） -->
              <template v-if="!projectSourceRules">
                <el-descriptions-item
                  labelClassName="invoiceLabelClassName"
                  contentClassName="invoiceContentClassName"
                >
                  <template slot="label"> 项目分管高管 </template>
                  <el-form-item ref="fzManagerName" prop="fzManagerName">
                    {{ form.fzManagerName }}
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item
                  labelClassName="invoiceLabelClassName"
                  contentClassName="invoiceContentClassName"
                >
                  <template slot="label"> 项目所属部门 </template>
                  <el-form-item ref="deptClassifyName" prop="deptId" style="width: 100%">
                    {{ form.deptClassifyName }}
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item
                  labelClassName="invoiceLabelClassName"
                  contentClassName="invoiceContentClassName"
                >
                  <template slot="label"> 项目来源 </template>
                  <el-form-item ref="projectSource" prop="projectSource">
                    {{ form.projectSource | dict(dictData.projectSource) }}
                  </el-form-item>
                </el-descriptions-item>
              </template>
            </template>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="deputyGeneralRules"
              :span="
                deputyGeneralRules && agreementRules
                  ? 2
                  : form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING'
                  ? 4
                  : 1
              "
            >
              <template slot="label">
                <span style="color: red">{{ deputyGeneralRules ? '*' : '' }}</span
                >分管副总
              </template>
              <el-form-item ref="generalManagerName" prop="generalManagerName">
                {{ form.generalManagerName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="deputyGeneralRules && !agreementRules"
              :span="form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING' ? 4 : 1"
            >
              <template slot="label">
                <span style="color: red">{{
                  deputyGeneralRules && !agreementRules ? '*' : ''
                }}</span
                >股东层
              </template>
              <el-form-item ref="shareholderName" prop="shareholderName">
                {{ form.shareholderName }}
              </el-form-item>
            </el-descriptions-item>

            <!-- 不立项不补签合同/已立项不签合同（不需要补合同）/已立项未签合同（需要补合同） -->
            <template
              v-if="
                form.invoiceApplicationType != 'NO_PROJECT_NO_CONTRACT_NEEDED' &&
                form.invoiceApplicationType != 'APPROVED_NO_CONTRACT_NEEDED' &&
                form.invoiceApplicationType != 'PROJECT_CONTRACT_PENDING' &&
                form.invoiceApplicationType != 'APPROVED_CONTRACT_PENDING'
              "
            >
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                :span="agreementRules ? 5 : 6"
              >
                <template slot="label">
                  <span style="color: red">{{ agreementRules ? '*' : '' }}</span>
                  合同名称
                </template>

                <el-form-item ref="agreementName" prop="agreementName">
                  {{ form.agreementName }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
              >
                <template slot="label"> 合同编号 </template>
                <el-form-item ref="agreementNum" prop="agreementNum">
                  {{ form.agreementNum }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
              >
                <template slot="label"> 合同总金额 （元） </template>
                <el-form-item ref="actualContractAmount" prop="actualContractAmount">
                  {{ form.actualContractAmount | applyAmount }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                v-if="agreementRules"
              >
                <template slot="label"> 合同待收金额（元） </template>
                <el-form-item ref="contractPendingAmount" prop="contractPendingAmount">
                  {{ form.contractPendingAmount | applyAmount }}
                </el-form-item>
              </el-descriptions-item>
            </template>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span>发票种类</span>
              </template>
              <el-form-item ref="invoiceLines" prop="invoiceLines">
                {{ form.invoiceLines | dict(dictData.invoiceLines) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="form.status == 100 && form.offsetStatus == 20"
            >
              <template slot="label">
                <span>发票状态</span>
              </template>
              <el-form-item ref="offsetStatus" prop="offsetStatus">
                <span>{{ form.offsetStatus | dict(dictData.offsetStatus) }}</span>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开具发票类型
              </template>
              <el-form-item prop="invoiceType" ref="invoiceType">
                {{ form.invoiceType | dict(dictData.invoiceType) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="1"
            >
              <template slot="label">
                <span style="color: red">*</span>
                增值税类型
              </template>
              <el-form-item prop="vatType" ref="vatType">
                {{ form.vatType | transitionType(zzsList) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="form.status == 100 && form.offsetStatus == 20 ? 2 : 3"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开票内容
              </template>
              <el-form-item prop="vatData" ref="vatData">
                {{ form.vatData | transitionData(dictData.VatDataAll) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="1"
            >
              <template slot="label">
                <span style="color: red">*</span>
                增值税率（%）
              </template>
              <el-form-item prop="vatRate" ref="vatRate"> {{ form.vatRate }} </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="1"
              label="是否优先发放"
            >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="1"
                >是</el-radio
              >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="0"
                >否</el-radio
              >
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="5"
            >
              <template slot="label">
                <span style="color: red">*</span>
                地址
              </template>
              <el-form-item prop="address" ref="address">{{ form.address }} </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="3"
            >
              <template slot="label">
                <span style="color: red">*</span>
                电话
              </template>
              <el-form-item prop="phone" ref="phone">{{ form.phone }} </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="5"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开户行
              </template>
              <el-form-item prop="openingBank" ref="openingBank"
                >{{ form.openingBank }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="3"
            >
              <template slot="label">
                <span style="color: red">*</span>
                账号
              </template>
              <el-form-item prop="account" ref="account">
                {{ form.account }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="8"
            >
              <template slot="label">
                <span style="color: red">*</span>
                发票备注（按甲方要求填写开票备注信息，如甲方无要求，则填写“无”）
              </template>
              <el-form-item ref="invoiceRemark" prop="invoiceRemark" style="width: 100%">
                {{ form.invoiceRemark }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="8"
              v-if="specialExplainRules"
            >
              <template slot="label">
                <span style="color: red">*</span>
                特殊情况说明 {{ specialExplain }}
              </template>
              <el-form-item
                ref="specialSituationDesc"
                prop="specialSituationDesc"
                style="width: 100%"
                >{{ form.specialSituationDesc }}
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <el-descriptions v-if="form.status == 100" :column="2" border direction="vertical">
          <el-descriptions-item label="发票编号">{{ form.invoicingNum }}</el-descriptions-item>
          <el-descriptions-item label="财务开票备注">{{ form.remark }}</el-descriptions-item>
          <el-descriptions-item label="附件">
            <span
              v-for="(item, index) in form.affirmCostFileList"
              :key="index"
              class="invoice-file-text"
            >
              <a
                class="fj-text"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${item.filePath}`"
              >
                {{ item.fileName }}
              </a>
            </span>
          </el-descriptions-item>
        </el-descriptions>

        <el-table ref="multipleTable" border :data="form.invoiceApplicationDetailList">
          <el-table-column fixed="left" label="序号" type="index" align="center" width="80">
          </el-table-column>
          <el-table-column
            prop="stageName"
            fixed="left"
            label="阶段名称"
            min-width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="paymentAmount" label="本期金额" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.paymentAmount | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceAmount"
            :show-overflow-tooltip="false"
            label="开票金额"
            min-width="110"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.invoiceAmount | applyAmount }}
            </template>
          </el-table-column>
          <!-- 流程结束才能看到 -->
          <template v-if="form.status == 100">
            <el-table-column prop="vatType" label="增值税类型" min-width="320" align="center">
              <template slot-scope="scope">
                {{ scope.row.vatType | transitionType(zzsList) }}
              </template>
            </el-table-column>
            <el-table-column prop="VatData" label="开票内容" min-width="320" align="center">
              <template slot-scope="scope">
                {{ scope.row.vatData | transitionData(dictData.VatDataAll) }}
              </template>
            </el-table-column>
            <el-table-column prop="vatRate" label="增值税税率" min-width="110" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.vatRate"> {{ scope.row.vatRate }} %</span>
              </template>
            </el-table-column>
            <el-table-column prop="vatFee" label="增值税" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.vatFee | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="additionalTaxFee" label="附加税" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.additionalTaxFee | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="stampTaxFee" label="印花税" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.stampTaxFee | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="taxesFee"
              fixed="right"
              min-width="110"
              label="总税费"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taxesFee | applyAmount }}
              </template>
            </el-table-column>
          </template>
        </el-table>
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="合计" labelStyle="width:10%">
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" labelStyle="width:10%">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
        </el-descriptions>
        <!-- 意见区 -->
        <OpinionArea
          ref="OpinionArea"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
          :title="'发票申请进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="info" plain @click="$emit('offsetCancel')">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OffsetDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    offsetId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false, // 表格 加载
      dictData: {
        invoiceType: [],
        VatDataAll: [],
        projectSource: [],
        companyType: [],
        invoiceLines: [],
        offsetStatus: [],
        invoiceApplicationType: [],
      },
      zzsList: [], //增值税类型
      form: {},
      instInvolved: {}, //当前进度处理人数组
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
    }
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.invoiceApplicationDetailList) {
        this.form.invoiceApplicationDetailList.forEach(v => {
          if (v.invoiceAmount) {
            num += Number(String(v.invoiceAmount).replace(/,/g, ''))
          }
        })
      }
      return num
    },
    // 项目校验
    projectRules() {
      /*
       *   已立项未签合同（需要补合同）
       *   已立项不签合同（不需要补合同）
       *   不补立项不补签合同
       */
      if (
        this.form.invoiceApplicationType == 'APPROVED_CONTRACT_PENDING' ||
        this.form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED'
      ) {
        return true
      } else {
        return false
      }
    },
    // 合同校验
    agreementRules() {
      // 有合同未立项（不需要补立项）
      if (this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED') {
        return true
      } else {
        return false
      }
    },
    // 特殊情况说明
    specialExplain() {
      if (this.form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED') {
        return '（说明不签合同的原因。）'
      } else if (this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED') {
        return '（项目所属年份、项目负责人、不签合同原因。）'
      } else if (this.form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING') {
        return '（说明补立项情况、合同预计上传时间、项目负责人。）'
      } else {
        return ''
      }
    },
    specialExplainRules() {
      /*
       *   已立项不签合同（不需要补合同）
       *   不补立项不补签合同
       *   未立项未签合同（需要后补项目和合同）
       */
      if (
        this.form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING'
      ) {
        return true
      } else {
        return false
      }
    },
    deputyGeneralRules() {
      if (
        this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED' ||
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING'
      ) {
        return true
      } else {
        return false
      }
    },
    projectSourceRules() {
      if (
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.getDict()
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (val) {
        if (val && this.offsetId) {
          this.getData()
        }
      },
    },
  },
  methods: {
    getData() {
      this.loading = true
      this.$api.invoice
        .selectDetailById(this.offsetId)
        .then(res => {
          this.form = res.data
          this.instInvolved = {
            instInvolvedUserName: res.data.instInvolvedUserName
              ? res.data.instInvolvedUserName
              : [],
            status: res.data.status,
          }
          if (this.form.vatType) {
            this.$api.vat
              .getVatDataList({ pageNow: 1, pageSize: 999 })
              .then(res => {
                if (res.data?.records) {
                  this.dictData.VatDataAll = res.data.records
                }
              })
              .catch(err => {
                console.log(err)
              })
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getDict() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE_FULL', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('OFFSET_STATUS', true)
        .then(res => {
          this.dictData.offsetStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_LINES', true)
        .then(res => {
          this.dictData.invoiceLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_TYPE', true)
        .then(res => {
          this.dictData.invoiceType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE_COLLECT', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_APPLICATION_TYPE', true)
        .then(res => {
          this.dictData.invoiceApplicationType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
}
</script>

<style scoped lang="scss">
.ticket_box {
  margin-top: 16px;

  .ticket_div {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    span {
      display: inline-block;
      margin: 0 4px;
      text-decoration: underline;
    }

    .section {
      width: 60px;
      text-align: center;
    }

    .lineBtn {
      float: right;
      margin-top: 10px;
    }
    .jilvBtn {
      float: right;
      margin-left: 8px;
      margin-top: 10px;
    }
  }
}

.content {
  height: calc(90vh - 164px);
  overflow: auto;
}
.w_100 {
  width: 100%;
}
/deep/.labelClassName {
  width: 12.5%;
}
</style>
